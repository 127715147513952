'use client';

import { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

interface ReCaptchaProviderProps {
  children: ReactNode;
}

const ReCaptchaProvider = ({ children }: ReCaptchaProviderProps) => {
  const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

  if (!siteKey) {
    console.error('La clé du site reCAPTCHA n\'est pas définie');
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey || ''}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default ReCaptchaProvider;
