"use client";
import XSVG from "@/components/ui/X";
import { useScopedI18n } from "@/locales/client";
import { useMenu } from "@/utils/useMobileMenu";
import { AnimatePresence, motion } from "framer-motion";
import { Facebook, Instagram, Linkedin, Youtube } from "lucide-react";
import { config } from "../../../../site.config";
type Props = {};

const NavMobile = (props: Props) => {
  const scopedT = useScopedI18n("links");

  const { isOpen } = useMenu();

  const menuVars = {
    initial: {
      scaleY: 0,
      y: "100%",
    },
    animate: {
      scaleY: 1,
      y: "100%",
      transition: {
        duration: 0.5,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      scaleY: 0,
      y: "100%",
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1],
      },
    },
  };

  const containerVars = {
    initial: {
      transition: {
        staggerChildren: 0.09,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.09,
        staggerDirection: 1,
      },
    },
  };

  const mobileIconsVars = {
    initial: {
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.37, 0, 0.63, 1],
      },
    },
    open: {
      opacity: 1,
      transition: {
        ease: [0, 0.55, 0.45, 1],
        duration: 0.7,
      },
    },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={menuVars}
          initial="initial"
          animate="animate"
          exit="exit"
          className="absolute bottom-0 z-10 h-[26rem] w-full translate-y-full bg-background shadow-xl "
          style={{ transformOrigin: "top" }}
        >
          <motion.div
            variants={containerVars}
            initial="initial"
            animate="open"
            exit="initial"
            className="relative flex size-full flex-col items-center gap-4 pt-14 "
          >
            {config.MenuLinks.map((link, index) => {
              return (
                <div key={index}>
                  <MobileNavLink
                    aria-label={
                      //@ts-ignore
                      scopedT(link.langKey)
                    }
                    title={
                      //@ts-ignore
                      scopedT(link.langKey)
                    }
                    href={link.href}
                  />
                </div>
              );
            })}
            <div className="absolute  bottom-4 flex gap-10">
              <motion.a
                aria-label="facebook"
                variants={mobileIconsVars}
                href="https://www.facebook.com/SagaAudioCompagnie"
                target="__blank"
              >
                <Facebook width={25} height={25} />
              </motion.a>
              <motion.a
                aria-label="instagram"
                variants={mobileIconsVars}
                href="https://www.instagram.com/sac.studio.officiel/"
                target="__blank"
              >
                <Linkedin width={25} height={25} />
              </motion.a>
              <motion.a
                aria-label="youtube"
                variants={mobileIconsVars}
                href="https://www.youtube.com/@SagaAudioCompagnie"
                target="__blank"
              >
                <Youtube width={25} height={25} />
              </motion.a>
              <motion.a
                aria-label="instagram"
                variants={mobileIconsVars}
                href="https://www.instagram.com/sac.studio.officiel/"
                target="__blank"
              >
                <Instagram width={25} height={25} />
              </motion.a>
              <motion.a
                aria-label="twitter"
                variants={mobileIconsVars}
                href="https://x.com/sacstudio_off"
                target="__blank"
              >
                <XSVG width={25} height={25} />
              </motion.a>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

type MobileNavLink = {
  title: string;
  href: string;
};
const mobileLinkVars = {
  initial: {
    y: "20px",
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.37, 0, 0.63, 1],
    },
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 0.7,
    },
  },
};

const MobileNavLink = ({ title, href }: MobileNavLink) => {
  const { isOpen, toggleMenu } = useMenu();

  return (
    <motion.div
      variants={mobileLinkVars}
      className=" text-2xl uppercase text-foreground"
    >
      <motion.a
        aria-label="menu"
        whileHover={{ fontWeight: "bold" }}
        onClick={toggleMenu}
        href={href}
      >
        {title}
      </motion.a>
    </motion.div>
  );
};

export default  NavMobile ;
