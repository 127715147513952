import * as React from "react";

interface XSVGProps extends React.SVGProps<SVGSVGElement> {
  height: number;
  width: number;
  colorVar? : string
}
const XSVG: React.FC<XSVGProps> = ({ width, height, colorVar, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.822109 0.879883L7.64617 10.8286L0.490234 19.1199H2.01555L8.32211 11.813L13.334 19.1199H18.1405L10.9996 8.70988L17.758 0.879883H16.2337L10.3246 7.72551L5.62867 0.879883H0.822109Z"
      fill={colorVar ? `${colorVar}` : "var(--foreground)"}
    />
  </svg>
);
export default XSVG;
