// Client Component
"use client";
import { useChangeLocale, useCurrentLocale } from "../../locales/client";
import Image from 'next/image';
// Import the images directly from src/assets
import englishFlag from '../../assets/english.png';
import frenchFlag from '../../assets/france.png';

export default function LangSwitch() {
  const changeLocale = useChangeLocale();
  const locale = useCurrentLocale();

  // This function toggles between 'en' and 'fr'
  const toggleLocale = () => {
    const newLocale = locale === "en" ? "fr" : "en";
    changeLocale(newLocale);
  };

  return (
    <div
      onClick={toggleLocale}
      className="relative ml-auto flex cursor-pointer items-center justify-center gap-2 rounded-lg border-2 border-muted px-4 py-2"
    >
      {/* Display the correct flag based on the current locale */}
      {locale === "fr" ? (
        <Image
          src={englishFlag} // Use the imported image
          alt="UK Flag"
          width={24} // Set appropriate width
          height={24} // Set appropriate height
        />
      ) : (
        <Image
          src={frenchFlag} // Use the imported image
          alt="French Flag"
          width={24} // Set appropriate width
          height={24} // Set appropriate height
        />
      )}
    </div>
  );
}
