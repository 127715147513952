'use client';

import { ReactNode, useEffect } from 'react';
import ReCaptchaProvider from '@/utils/ReCaptchaProvider';

interface ClientProvidersProps {
  children: ReactNode;
}

const ClientProviders = ({ children }: ClientProvidersProps) => {
  
  useEffect(() => {
    if (typeof window !== 'undefined' && window.grecaptcha) {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('VOTRE_SITE_KEY', { action: 'submit' }).then(function(token: string) {
          // Si le reCAPTCHA est validé avec succès, envoie l'événement à Google Tag Manager
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'recaptchaSuccess',  // Correspond au nom de l'événement personnalisé dans GTM
            recaptchaToken: token
          });
        });
      });
    }
  }, []);
  
  return (
    <ReCaptchaProvider>
      {children}
    </ReCaptchaProvider>
  );
};

export default ClientProviders;
