"use client";
import { ArrowBigDown, ArrowBigUp } from "lucide-react";

function ArrowNav() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function scrollToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  return (
    <div className="fixed bottom-5 right-5 z-[1000] flex flex-col gap-2">
      <div
        className="flex size-12 cursor-pointer items-center justify-center rounded-full border border-white/40 bg-white/80 shadow-2xl backdrop-blur-[0.5rem] transition-all hover:scale-[1.15] active:scale-105 dark:bg-gray-950"
        onClick={scrollToTop}
      >
        <ArrowBigUp width={25} height={25} />
      </div>
      <div
        className="flex size-12 cursor-pointer items-center justify-center rounded-full border border-white/40 bg-white/80 shadow-2xl backdrop-blur-[0.5rem] transition-all hover:scale-[1.15] active:scale-105 dark:bg-gray-950"
        onClick={scrollToBottom}
      >
        <ArrowBigDown width={25} height={25} />
      </div>
    </div>
  );
}

export default ArrowNav;
